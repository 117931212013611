<template>
	<div id="ag-grid-demo">
		<div class="vx-row">
			<div class="vx-col w-full xl:w-1/2 mb-base">
				<!-- Whitelist Domain -->
				<whitelist-domain />
			</div>
			<div class="vx-col w-full xl:w-1/2 mb-base">
				<!-- Whitelist Email -->
				<whitelist-email />
			</div>
		</div>
	</div>
</template>

<script>
	import WhitelistDomain from '@/components/ag-grid-tables/WhitelistDomain.vue'
	import WhitelistEmail from '@/components/ag-grid-tables/WhitelistEmail.vue'

	export default {
		components: {
			WhitelistEmail,
			WhitelistDomain,
		},
		data() {
			return {
				
			}
		},
	}

</script>