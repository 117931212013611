<template>
	<div id="ag-grid-demo">
		<vx-card id="phone-grid-loader" class="vs-con-loading__container">
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowModelType="rowModelType"
				serverSideStoreType="partial"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@grid-ready="onGridReady"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>

		<!-- Delete Prompt -->
		<vs-prompt id="delete-prompt" vs-title="Remove from whitelist?" :vs-is-valid="deleteWhitelist['input'] == deleteWhitelist['password']" vs-accept-text="Confirm" @vs-cancel="closeDeletePrompt" @vs-accept="confirmDeleteWhitelist" :vs-active.sync="deleteWhitelist['prompt']">
			<div class="con-exemple-prompt">
				<vs-input v-model="deleteWhitelist['input']" class="w-full" placeholder="Enter password"/>
			</div>
		</vs-prompt>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false,
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep'
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				rowModelType: "serverSide",
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					width: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?domain_name=${data.value}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Action Email',
					field: 'user_email',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				],
				deleteNode: {
					domain_id: [],
				},
				deleteWhitelist: {
					input: '',
					password: '',
					prompt: false
				}
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			user_email() {
				return JSON.parse(localStorage.getItem('userDetails')).email;
			},
			hasDeletePermission() {
				const userArray = [
					'alessandrod@radix.email',
					'harshde@radix.email',
					'aman@radix.email',
					'sandy@radix.email',
					'sandeep.r@radix.email',
					'sandeep@radix.email'
				];
				
				return userArray.includes(this.user_email)
			}
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#phone-grid-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#phone-grid-loader > .con-vs-loading");
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onGridReady(params) {
				let self = this;

				const endpoint = `${this.$SERVERSIDE}/node-sources/whitelisted-domains`;

				// Set Data Source
				params.api.setServerSideDatasource({
					getRows(params) {
						fetch(endpoint, {
							method: 'post',
							body: JSON.stringify(params.request),
							headers: {"Content-Type": "application/json; charset=utf-8"}
						})
						.then(httpResponse => httpResponse.json())
						.then(response => {
							if(response.lastRow) {
								// clear all overlays
								self.gridApi.hideOverlay();
								params.successCallback(response.rows, response.lastRow);
							}
							else {
								// clear all overlays
								self.gridApi.hideOverlay();
								// show 'no rows' overlay
								self.gridApi.showNoRowsOverlay();
								params.successCallback([], 0);
							}
						})
						.catch(error => {
							console.error(error);
							params.failCallback();
						})
					}
				});
			},
			openDeletePrompt() {
				// Open delete popup
				this.deleteWhitelist['prompt'] = true;
			},
			closeDeletePrompt() {
				// Close delete popup
				this.deleteWhitelist['prompt'] = false;
				// Clear input
				this.deleteWhitelist['input'] = '';
			},
			confirmDeleteWhitelist() {
				let self = this;
				// Post Axios Call
				this.$axiosSecure.post('/delete-whitelisted-domain', {
					...self.deleteNode,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Whitelist success',
						text: 'The selected domain(s) has been removed from whitelist'
					});
					
					// Reset Table Data
					this.gridApi.setFilterModel(null);
					this.gridApi.setSortModel([
						{
						colId: "created_at",
						sort: "desc"
						}
					]);
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			openConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Remove the selected domain(s) from whitelist?',
					accept: this.acceptDeleteAlert
				});
			},
			acceptDeleteAlert() {
				let self = this;
				// Post Axios Call
				this.$axiosSecure.post('/delete-whitelisted-domain', {
					...self.deleteNode,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Whitelist success',
						text: 'The selected domain(s) has been removed from whitelist'
					});
					
					// Reset Table Data
					this.gridApi.setFilterModel(null);
					this.gridApi.setSortModel([
						{
						colId: "created_at",
						sort: "desc"
						}
					]);
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this,
					rangeSelection = this.gridApi.getCellRanges(),
					singleCell = false;

				// Reset Deactivate Node ID
				this.deleteNode['domain_id'] = [];

				if(this.hasDeletePermission) {
					if(rangeSelection) {
						// Get selected cells & iterate through them to find rules
						rangeSelection.forEach(item => {
							let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
								end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
								isRightClicked = false;
							
							for (let i = start; i <= end; i++) {
								if(self.gridApi.getDisplayedRowAtIndex(i)['data']['id'] == params['node']['data']['id']) {
									isRightClicked = true;
									singleCell = true;
								}
								self.deleteNode['domain_id'].push(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain_id']);
							}
							
							if(!isRightClicked) {
								self.deleteNode['domain_id'].push(params['node']['data']['domain_id']);
							}
						});
						
						// If only one cell is selected
						if(rangeSelection.length == 1 && singleCell && rangeSelection[0]['start']['rowIndex'] == rangeSelection[0]['end']['rowIndex']) {
							result = [
								'copy',
								'copyWithHeaders',
								'paste',
								'separator',
								'export',
								'separator',
								{
									name: "Delete",
									action: () => {
										this.openDeletePrompt();
									},
								},
							];
						}
						else {
							result = [
								'copy',
								'copyWithHeaders',
								'paste',
								'separator',
								'export',
								'separator',
								{
									name: "Bulk Delete",
									action: () => {
										this.openDeletePrompt();
									},
								},
							];
						}
					}
					else {
						result = [
							'copy',
							'copyWithHeaders',
							'paste',
							'separator',
							'export',
							'separator',
							{
								name: "Delete",
								action: () => {
									self.deleteNode['domain_id'].push(params['node']['data']['domain_id']);
									this.openDeletePrompt();
								},
							},
						];
					}
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			},
			assignDeletePassword() {
				// Check user email
				switch (this.user_email) {
					case 'alessandrod@radix.email':
						this.deleteWhitelist['password'] = 'Devs123'
						break;
						
					case 'harshde@radix.email':
						this.deleteWhitelist['password'] = 'Devs123'
						break;

					case 'aman@radix.email':
						this.deleteWhitelist['password'] = 'bxxu9ahnskhu'
						break;

					case 'sandy@radix.email':
						this.deleteWhitelist['password'] = 'a8jvpcd7eyz5'
						break;

					case 'sandeep.r@radix.email':
						this.deleteWhitelist['password'] = 'a8jvpcd7eyz5'
						break;

					case 'sandeep@radix.email':
						this.deleteWhitelist['password'] = 'a8jvpcd7eyz5'
						break;
				
					default:
						break;
				}
			}
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};

			// Set delete password
			this.assignDeletePassword();
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);
		},
	}

</script>